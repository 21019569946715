<template>
  <div id="app">
    <TelegramDesktop />
  </div>
</template>

<script>
import './assets/reset.css'; // 确保路径正确指向你的reset.css文件
import TelegramDesktop from './components/TelegramDesktop.vue';

export default {
  name: 'App',
  components: {
    TelegramDesktop
  }
}
</script>

<style>
/* 你可以在这里添加全局样式 */
</style>s
